import * as Sentry from "@sentry/react";
import versionInfo from './version.json';

const configs = {
    development: {
        env: 'development',
        apiUrl: 'http://arch.local:8603',
        affiliate_url: 'http://reclipper-local.onpy.mx:3000',
        version: versionInfo.commit,
        // STRIPE_PUBLIC_KEY: 'pk_test_51QVf2EBGzna3MdTWYkpcJiCwyQpfiGgzVmrVymopzI1roYwWIFPUqqAd1i4jRzum1bheWxnl6WNqvWBODKIkCYTS00WE4kRJ5P',
        ///DENIS MX
        // STRIPE_PUBLIC_KEY: 'pk_test_51QVeupBR01jjA6Ll8Xz2AbSSAgF53TrAPtsf17090laWPJaQe4ll8pbSBb1yepxwePx3de84UsN2Rk3k52mGcFWx00ixXuWZg4',
        STRIPE_PUBLIC_KEY: 'pk_test_51R05axFh337XkTYAZeWLQCVR8PPmnGivowxwfTFbhJh1ttVuML7nZmRyI1vE3xsYAJqnUZeld0911QITD4kaRRTT00fav4pV7i',
        google_auth_client_id: '499004089622-kiuo5hbqlv2527qnusoha0b4n5s88ldb.apps.googleusercontent.com',
        google_auth_redirect_url: ''
,
    },
    production: {
        env: 'production',
        apiUrl: 'https://api.reclipper.com',
        affiliate_url: 'https://reclipper.com',
        version: versionInfo.commit,
        STRIPE_PUBLIC_KEY: 'pk_live_51QVGSNRptZVlKKV6HwGrulL2J1wzseuri2uaAy5g3yUrKbzeNBboc8DVqfvdQucEG86Z3DkfQwWlUrTZmryiJmmB00it7i3i9a',
        google_auth_client_id: '203460985594-uviiihhshmq86t2j5fblnm1g1an8n494.apps.googleusercontent.com',
        google_auth_redirect_url: ''
    },
};

type Environment = 'development' | 'production';

const currentEnv: Environment = (process.env.NODE_ENV as Environment) || 'development';

export const initSentry = () => {
    if (currentEnv === 'production') {
        Sentry.init({
            dsn: "https://b32eddaa12f4ffae35fd8af908fd3979@o4508424058765312.ingest.us.sentry.io/4508424096448512",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/api\.reclipper\.com\/api/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
}

export const config = configs[currentEnv];
